var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-container", { staticClass: "d-flex justify-center mb-6" }, [
        _c(
          "div",
          { staticClass: "pt-12" },
          [
            _c(
              "v-form",
              {
                ref: "form",
                attrs: { "lazy-validation": "" },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "no-border" },
                  [
                    _c("v-card-title", { staticClass: "pb-0" }, [
                      _c("div", { staticClass: "text-center pb-16" }, [
                        _c("img", {
                          staticClass: "hidden-xs-only pt-9",
                          attrs: {
                            src: require("@/assets/logo-cor-primaria.png"),
                            width: "148",
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "no-border", staticStyle: { width: "340px" } },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "pb-0 pt-0 title-login-flow" },
                      [_vm._v(" Código de verificação ")]
                    ),
                    _c("v-card-title", { staticClass: "body-login-flow" }, [
                      _vm._v(
                        " Insira o código enviado por e-mail para poder acessar a sua conta. "
                      ),
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "px-4 pb-4 pt-0" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "",
                            dense: "",
                            rounded: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            placeholder: "Código",
                            rules: _vm.rules.tokenValidator,
                            "error-messages": _vm.errorTokenMessage,
                          },
                          model: {
                            value: _vm.recoveryToken,
                            callback: function ($$v) {
                              _vm.recoveryToken = $$v
                            },
                            expression: "recoveryToken",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-right solicitar-codigo-login-flow",
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.loading,
                                  to: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resendToken()
                                  },
                                },
                              },
                              [_vm._v("Solicitar outro código")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "button-action-login-flow" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled: _vm.timerCount != 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.validateToken()
                              },
                            },
                          },
                          [_vm._v(" Avançar ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-app>
        <v-container class="d-flex justify-center mb-6">
            <div class="pt-12">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card class="no-border">
                        <v-card-title class="pb-0">
                            <div class="text-center pb-16">
                                <img :src="require('@/assets/logo-cor-primaria.png')" width="148"
                                    class="hidden-xs-only pt-9" />
                            </div>
                        </v-card-title>
                    </v-card>
                    <v-card style="width: 340px;" class="no-border">
                        <v-card-title class="pb-0 pt-0 title-login-flow">
                            Código de verificação
                        </v-card-title>
                        <v-card-title class="body-login-flow">
                            Insira o código enviado por e-mail para poder acessar a sua conta.
                        </v-card-title>
                        <v-card-text class="px-4 pb-4 pt-0">
                            <v-text-field v-model="recoveryToken" label="" dense rounded outlined persistent-placeholder
                                placeholder="Código" :rules="rules.tokenValidator"
                                :error-messages="errorTokenMessage" />
                            <p class="text-right solicitar-codigo-login-flow">
                                <a @click="resendToken()" :loading="loading" :disabled="loading" to="">Solicitar outro
                                    código</a>
                            </p>
                        </v-card-text>
                        <v-card-actions class="button-action-login-flow">
                            <v-spacer />
                            <v-btn color="primary" @click="validateToken()" :disabled="timerCount != 0">
                                Avançar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </div>
        </v-container>
    </v-app>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import userService from '@/services/userService.js';
import { eventBus } from '../../../../main.js'

export default {
    name: 'ModalTfaToken',
    props: {
        username: {
            require: true
        },
        token: {
            required: false,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        timerCount: 5,
        recoveryToken: '',
        message: '',
        errorTokenMessage: '',
        rules: {
            tokenValidator: [(v) => !!v || 'Código é obrigatório.'],
            invalidToken: () => true || this.errorTokenMessage,
        },

    }),

    created() {
        // ❌ warning, props are readonly!
        this.recoveryToken = this.token
    },

    methods: {
        ...mapActions('user', ['loginTwofa', 'errorSubmit', 'setMessageLogin']),
        showToast(message, type, topToast) {
            let toast = {
                show : true,
                type : type,
                message : message,
                topToast: topToast
            }
            eventBus.$emit('openToast', toast);
        },
        async validateToken() {
            await this.$refs.form.validate();
            if (!this.valid) return;

            this.error = '';

            var response = await this.loginTwofa(this.recoveryToken).then(() => {
                if (this.error != null) {
                    this.timerCount = 5;
                    this.errorTokenMessage = this.error;
                } else {
                    this.recoveryToken = '';
                    this.errorTokenMessage = '';
                    this.$router.push(`/home`);
                }
            });

        },

        async resendToken() {
            this.loading = true;
            try {
                var response = await userService.checkTokenExpirationTime(this.username);
                if (response.data === true) {
                    this.showToast('Novo código solicitado com sucesso. Verifique seu e-mail.','success', 14);
                }
            } catch (error) {
                this.errorMessage = error.response.data.erros[0];
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapState('user', {
            error: (state) => state.error,
        }),
    },

    watch: {
        timerCount: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.errorTokenMessage = '';
                    this.error = '';
                    this.message = 'Tente novamente, seu token expirou.'
                    this.invalidToken = true;
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    }
}

</script>
<style scoped>
.no-border {
    box-shadow: none !important;
    border: 0px solid #e7e7fa !important;
}
</style>

